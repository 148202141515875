import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaAnglesLeft } from 'react-icons/fa6';


const SiteMap = () => {

    const navigate = useNavigate();

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    const Menu = [
        {
            label: 'ACCUEIL',
            link: '/#hero',
        },
        {
            label: 'PMI TOGO CHAPTER',
            link: '/we#we',
            links: [
                { label: "Qui Sommes-nous ?", link: "/we#we", out: false },
                { label: "Objectifs & Missions", link: "/we#objectifs&missions", out: false },
                { label: "Membres dirigeants", link: "/we#headmember", out: false },
                { label: "Volontaires", link: "/we#volunteers", out: false },
            ],
        },

        {
            label: 'MEMBRES',
            link: '/members#member',
            links: [
                { label: "Avantages", link: "/members#advantages", out: false },
                { label: "Volontariat", link: "/members#volunteering", out: false },
            ],
        },

        {
            label: 'CARRIERE',
            link: '/devpro#prodev',
            links: [
                { label: "Certifications", link: "/devpro#certifications", out: false },
                { label: "Formations", link: "/devpro#formations", out: false },
                { label: "Offres d'emploi", link: "/devpro#jobs", out: false },
            ],
        },

        {
            label: 'EVENEMENTS',
            link: '/events#evenements',
            links: [
                { label: "Evènements locaux", link: "/events#localevents", out: false },
                { label: "Webinaires", link: "/events#webinaires", out: false },
                { label: "PMI Africa Conférence", link: "https://pmiafricaconference.com/", out: true },
                { label: "PMI Global", link: "https://www.pmi.org/events/calendar", out: true },
            ],
        },

        {
            label: 'PUBLICATIONS',
            link: '/blog#blog',
            links: [
                { label: "Blog", link: "/blog#blog", out: false },
                { label: "Documentation", link: "blog/docs#docs", out: false },
            ],
        },

        {
            label: 'CONTACT',
            link: '/contact#contact',
        },
    ];

    return (
        <div className="flex flex-col gap-[50px] pb-[100px]">

            <div className="xl:h-[90px] xl:block hidden" id="plan"></div>

            <section className="flex flex-col xl:gap-[50px]" id=''>
                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-black slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-black xl:px-[60px] px-5 xl:w-[1440px] slide-left">Plan du site</p>
                    </div>
                </motion.div>
                <div className="flex xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-black text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <section className=" xl:px-[60px] px-5">
                <div className="flex flex-col bg-white justify-between h-full px-5">
                    <div className='flex flex-col w-full font-pressura gap-10 pl-20'>

                        {Menu.map((item, idx) => (
                            item.links ? (
                                <div className="flex flex-col gap-3" key={idx}>
                                    <li className="xl:text-[20px] hover:text-[24px] text-tangerine cursor-pointer transition-one">
                                        <NavLink className={""} to={item.link}>
                                            {item.label}
                                        </NavLink>
                                    </li>

                                    <div className="bg-white w-[max-content] flex flex-col gap-3 text-black pl-10 transition-one">

                                        {item.links.map((line, index) => (
                                            line.out === true ?
                                            <li>
                                                <Link key={index} to={line.link} target="_blank" rel="noopener" className="hover:font-bold px-[10px] py-[6px]">{line.label}</Link>
                                            </li>
                                                :
                                            <li>
                                                <Link key={index} to={line.link} className="hover:font-bold px-[10px] py-[6px]">{line.label}</Link>
                                            </li>
                                        ))}

                                    </div>
                                </div>
                            ) : (
                                <li className="xl:text-[20px] hover:text-[24px] text-tangerine cursor-pointer transition-one">
                                    <NavLink className={""} to={item.link}>
                                        {item.label}
                                    </NavLink>
                                </li>
                            )
                        ))}

                    </div>

                </div>

            </section>
        </div>
    )
}

export default SiteMap