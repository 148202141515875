import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const WebEvent = ({ id, coverImg, title, date }) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <motion.div
            ref={ref}
            animate={inView ? 'visible' : 'hidden'}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 },
            }}
            transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
        >
            <div className="bg-lightgrey flex xl:flex-row flex-col items-center justify-center gap-5 px-5 py-[15px] xl:h-[220px] xl:w-[800px] w-full rounded-md shadow-md">
                <div className="xl:w-3/6 w-full xl:h-[200px] h-[150px] bg-cover rounded-md" style={{ backgroundImage: `url(${coverImg})` }} />

                <div className="xl:w-3/6 w-full flex flex-col gap-[15px]">
                    <p className="title font-bold font-pressura text-violet text-[18px] uppercase">{title}</p>

                    <div className="flex xl:flex-row flex-col xl:gap-0 gap-5 justify-between xl:items-center font-pressura">
                        <p className="text-black">Date: {date}</p>
                        <Link to={"/events/event/" + id + "#thisevent"} className="buttonviolet">En savoir plus</Link>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default WebEvent