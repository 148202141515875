import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import datapmi from "../../assets/csv/Current_Month_Active_Members_List.csv";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const PmiTogoData = () => {
  const [csvData, setCsvData] = useState([]);
  const today = new Date();
  const thisYear = today.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(datapmi);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvString = decoder.decode(result.value);

      Papa.parse(csvString, {
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          setCsvData(results.data);
        },
      });
    };

    fetchData();
  }, []);

  const joinedThisYear = csvData.filter((member) => {
    const joinDate = member["Month(Chapter Join Date)"];
    return joinDate && joinDate.includes(thisYear);
  }).length;

  const totalMembers = csvData.length;

  const certifiedMembers = csvData.filter((member) => {
    const certificationStatus = member["Certification Status for Person"];
    return certificationStatus && certificationStatus === "ACTIVE";
  }).length;

  const studentMembers = csvData.filter((member) => {
    const classification = member["Chapter Membership Classification"];
    return classification && classification === "Student";
  }).length;

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <section className="flex flex-col justify-between mt-[30px] items-center gap-[45px] max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
      {/* Title */}
      <p className="xl:text-[35px] text-[20px] sm:text-[24px] lg:text-[30px] font-bold textAgrandirTitle text-center">
        Statistiques d'adhésion
      </p>

      {/* Statistics Grid */}
      <motion.div
        ref={ref}
        transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
      >
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-[40px] justify-center max-w-[1440px] items-center">
          {/* Statistic Card 1 */}
          <div className="bg-lightgrey flex flex-col justify-around items-center gap-5 py-[20px] sm:py-[30px] px-[15px] w-full sm:w-[200px] lg:w-[275px] h-[120px] sm:h-[150px] lg:h-[170px]">
            <p className="textPressuraStat text-[14px] sm:text-[16px] lg:text-[17px] font-agrandir text-center mt-2">
              Membres de cette année
            </p>
            <span className="text-aqua font-agrandir font-bold text-[24px] sm:text-[30px] lg:text-[40px]">
              {inView && (
                <CountUp start={0} end={joinedThisYear} duration={2.5} />
              )}
            </span>
          </div>

          {/* Statistic Card 2 */}
          <div className="bg-lightgrey flex flex-col justify-around items-center gap-5 py-[20px] sm:py-[30px] px-[15px] w-full sm:w-[200px] lg:w-[275px] h-[120px] sm:h-[150px] lg:h-[170px]">
            <p className="textPressuraStat text-[14px] sm:text-[16px] lg:text-[17px] font-agrandir text-center mt-2">
              Total des Membres
            </p>
            <span className="text-violet font-agrandir font-bold text-[24px] sm:text-[30px] lg:text-[40px]">
              {inView && (
                <CountUp start={0} end={totalMembers} duration={2.5} />
              )}
            </span>
          </div>

          {/* Statistic Card 3 */}
          <div className="bg-lightgrey flex flex-col justify-around items-center gap-5 py-[20px] sm:py-[30px] px-[15px] w-full sm:w-[200px] lg:w-[275px] h-[120px] sm:h-[150px] lg:h-[170px]">
            <p className="textPressuraStat text-[14px] sm:text-[16px] lg:text-[17px] font-agrandir text-center mt-2">
              Membres Certifiés
            </p>
            <span className="text-tangerine font-agrandir font-bold text-[24px] sm:text-[30px] lg:text-[40px]">
              {inView && (
                <CountUp start={0} end={certifiedMembers} duration={2.5} />
              )}
            </span>
          </div>

          {/* Statistic Card 4 */}
          <div className="bg-lightgrey flex flex-col justify-around items-center gap-5 py-[20px] sm:py-[30px] px-[15px] w-full sm:w-[200px] lg:w-[275px] h-[120px] sm:h-[150px] lg:h-[170px]">
            <p className="textPressuraStat text-[14px] sm:text-[16px] lg:text-[17px] font-agrandir text-center mt-2">
              Etudiants
            </p>
            <span className="text-aqua font-agrandir font-bold text-[24px] sm:text-[30px] lg:text-[40px]">
              {inView && (
                <CountUp start={0} end={studentMembers} duration={2.5} />
              )}
            </span>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default PmiTogoData;
