import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import grwtSymbolTangerine from "../../assets/pmi/aqua/pmi_sym_grwt_aqua_rgb.png";
import { IoMdCalendar } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";

import SponsorList from "../../components/sponsoring/SponsorList";

//slider imgs
import slide1 from "../../assets/images/focused-workaholic-african-american-businesswoman-working-company-financial-charts-presentation.jpg";
import slide2 from "../../assets/images/d.jpg";
import slide3 from "../../assets/images/pexels-christina-morillo-1181422.jpg";

import { Link } from "react-router-dom";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestoreDatabase } from "../../utils/firebase";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import { FaAngleDoubleRight, FaLink } from "react-icons/fa";
import PmiTogoData from "../../components/pmi-togo-data/PmiTogoData";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

import aufLogo from "../../assets/images/partners/auf_logo.png";
import HomeEasyAccessList from "../../Sections/HomeEasyAccessList";

const Home = () => {
  const sliderImg = [
    {
      src: slide1,
      text: "Slide One",
    },
    {
      src: slide2,
      text: "Slide Two",
    },
    {
      src: slide3,
      text: "Slide Three",
    },
  ];

  const [lastArticles, setLastArticles] = useState([]);
  const [nextEvent, setNextEvent] = useState([]);

  useEffect(() => {
    //read last articles data from firebase
    const readLastArticleData = async () => {
      const articlesRef = query(
        collection(firestoreDatabase, "articles"),
        orderBy("createdAt", "desc"),
        where("isVisible", "==", true),
        limit(3)
      );
      onSnapshot(articlesRef, (snapshot) => {
        setLastArticles(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    };

    //read next event data from firebase
    const readNextEventData = async () => {
      const currentDate = new Date().toISOString();

      const nextEventRef = query(
        collection(firestoreDatabase, "events"),
        orderBy("date", "asc"),
        where("isVisible", "==", true),
        where("date", ">=", currentDate),
        limit(3)
      );
      onSnapshot(nextEventRef, (snapshot) => {
        setNextEvent(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    };

    readNextEventData();

    readLastArticleData();
  }, []);

  const [ref, inView] = useInView({
    triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
  });

  return (
    <div className="flex flex-col xl:gap-[60px] gap-[50px] xl:pb-[60px] pb-[80px]">
      <div className="xl:h-[30px] xl:block hidden" id="hero"></div>
      <section className="flex justify-center items-center">
        <div className="w-full xl:min-h-[80vh] min-h-[90vh] xl:mt-[2px] flex items-center justify-between cursor-pointer 2xl:max-w-[1980px]">
          <Slider
            {...{
              dots: true,
              infinite: true,
              speed: 300,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              pauseOnHover: false,
              focusOnSelect: true,
              arrows: false,
              className: "w-[100%]",
              useCSS: true,
              fade: true,
            }}
          >
            {sliderImg.map((img, idx) => (
              <div
                className="w-[100%] xl:min-h-[80vh] xl:h-[80vh] h-[90vh] relative" // Added `relative` for pseudo-element
                key={idx}
              >
                {/* Image with Shadow Overlay */}
                <div
                  className="h-full w-full bg-center bg-cover flex justify-center items-end xl:px-[60px] px-5 relative"
                  style={{ backgroundImage: `url(${img.src})` }}
                >
                  {/* Shadow Overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-30"></div>{" "}
                  {/* Adjust opacity as needed */}
                  {/* Content */}
                  <div className="mb-10 2xl:text-[40px] sm:text-[32px] text-[20px] xl:pb-[80px] pb-[150px] heading flex flex-col font-agrandir font-bold text-white relative z-10 text-center xl:text-left">
                    <div>
                      <p className="textSpaceMonoWhite slide-up drop-shadow-2xl shadow-black">
                        Bienvenue au Chapitre <span className="text-tangerine">PMI</span> TOGO
                      </p>
                    </div>
                    <div>
                      <p className="textSpaceMonoWhite slide-up drop-shadow-2xl shadow-black">
                        Votre passerelle vers l'excellence dans
                      </p>
                    </div>
                    <div>
                      <p className="textSpaceMonoWhite slide-up drop-shadow-2xl shadow-black">
                        la <span className="text-tangerine">gestion de projets</span> au cœur du{" "}
                        <span className="textSpaceMonoWhite text-tangerine">
                          {" "}
                          Togo{" "}
                        </span>
                        !
                      </p>
                    </div>
                  </div>
                  {/* Symbol */}
                  <div className="xl:block hidden absolute xl:right-10 xl:bottom-10">
                    <img
                      src={grwtSymbolTangerine}
                      className="xl:w-[60px] w-10"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/** Add content to homepage to make it more welcoming */}
      <div className="flex align-center justify-center xl:gap-[100px] gap-[50px] w-full max-w-[1200px] mx-auto">
        <section>
          <HomeEasyAccessList />
        </section>
      </div>

      <div className="relative h-[400px] w-full">
        {/* Background Image */}
        <img
          src="/background.png"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover filter blur-sm"
        />

        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="max-w-5xl text-center mx-4 sm:mx-8 lg:mx-12 xl:mx-auto px-4 sm:px-6 lg:px-8">
            {" "}
            {/* Responsive max-width and padding */}
            <h1 className="textSpaceMonoWhite text-2xl sm:text-3xl lg:text-4xl text-white font-bold">
              Développez vos compétences
            </h1>
            <p className="textSpaceMonoWhite text-[14px] sm:text-[16px] lg:text-[16px] text-white mt-4">
              Les chefs de projet doivent constamment améliorer leurs
              compétences pour rester compétitifs. L'obtention d'une
              certification PMP est une étape importante. Comme de plus en plus
              d'entreprises adoptent des méthodes flexibles en plus de
              l'approche traditionnelle en cascade, de nouveaux styles de
              gestion de projet sont demandés. La formation et la certification
              dans ces domaines sont donc d'autant plus utiles, en particulier
              dans l'économie actuelle.
            </p>
          </div>
        </div>
      </div>

      <div className="xl:grid contents justify-center items-center xl:gap-[90px] gap-[50px] xl:w-full">
        <PmiTogoData />

        <div className="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl xl:max-w-6xl">
          {/* Image Section */}
          <div className="w-full h-64 lg:w-1/2 lg:h-auto">
            <img
              className="h-[300px] sm:h-[400px] lg:h-[500px] lg:w-[1100px] object-cover"
              src="/global-2025.png"
              alt="Winding mountain road"
            />
          </div>

          {/* Content Section */}
          <div className="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:relative md:top-[-100px] lg:top-0 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
            <div className="flex flex-col p-8 sm:p-12 md:px-16">
              {/* Title */}
              <h2 className="textSpaceMono text-2xl uppercase text-green-800 lg:text-3xl xl:text-4xl">
                GLOBAL SUBMIT SERIES
              </h2>

              {/* Description */}
              <p className="mt-4 textSpaceMono font-[500] sm:text-[16px]">
                Connectez-vous avec des leaders d'entreprise, des innovateurs du
                secteur de la construction et des experts en gestion de projet
                déterminés à assurer la réussite des projets sur le continent et
                à l'international.
              </p>

              {/* Button */}
              <div className="mt-8">
                <Link
                  to={"https://pmiglobalsummitafrica.gcs-web.com/"}
                  target="_blank"
                >
                  <button className="headerBtnViolet">S'ENREGISTRER</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="flex flex-col gap-[30px] xl:max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          {/* Title */}
          <h1 className="textAgrandirTitle font-bold text-[20px] mb-4 sm:text-[24px] lg:text-[30px] xl:text-[30px] text-center">
            Récentes publications
          </h1>

          {/* Articles Grid */}

          <div
            className={`w-full h-full ${
              lastArticles.length > 0 ? "grid" : "flex"
            } grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-[80px] justify-between items-center pb-5`}
          >
            {lastArticles.length > 0 ? (
              lastArticles.map((article, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2.5 pb-[20px] shadow-md w-full sm:w-[300px] lg:w-[300px]"
                >
                  {/* Article Image */}
                  <div
                    className="w-full h-[200px] bg-cover"
                    style={{
                      backgroundImage: `url(${article?.data?.coverUrl})`,
                    }}
                  ></div>

                  {/* Article Content */}
                  <div className="flex flex-col justify-center gap-2.5 h-full w-full px-2.5 sm:px-[20px] rounded-xl">
                    <p className="textSpaceMonoViolet text-[16px] sm:text-[18px] lg:text-[18px] text-justify line-clamp-3">
                      {article?.data?.title}
                    </p>

                    {/* Date */}
                    <div className="flex items-center gap-[5px] text-grey font-pressura">
                      <IoMdCalendar />
                      <span className="headerLinkGray text-[10px] sm:text-[12px] lg:text-[14px]">
                        {article?.data?.createdAt &&
                          format(article?.data?.createdAt, "dd MMMM yyyy", {
                            locale: fr,
                          })}
                      </span>
                    </div>

                    {/* Rubrique */}
                    <div className="textSpaceMonoViolet flex items-center justify-center text-violet text-[12px] py-[2px] px-2 w-fit border-[1px] border-violet rounded-xl">
                      {article?.data?.rubrique}
                    </div>

                    {/* Read Article Link */}
                    <Link
                      to={"/blog/article/" + article.id + "#article"}
                      className="headerLinkViolet flex items-center gap-1 text-aqua text-[14px]"
                    >
                      LIRE PLUS
                      <FaAngleDoubleRight className="text-[14px]" />
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              /* No Articles Message */
              <div className="flex justify-center w-full font-agrandir px-4 sm:px-[60px] text-[15px] sm:text-[16px]">
                <div className="flex flex-col textSpaceMono text-center">
                  Nous n'avons encore rien posté. <br /> Revenez plus tard !
                  <span className="h-1 w-10 bg-tangerine mx-auto mt-2" />
                </div>
              </div>
            )}
          </div>

          {/* View All Articles Button */}
          {lastArticles.length > 0 && (
            <div className="w-full flex justify-center items-center mt-5">
              <Link to="/blog#blog">
                <button className="headerBtnViolet">
                  Lire tous les articles
                </button>
              </Link>
            </div>
          )}
        </section>

        <section className="flex flex-col justify-between items-center gap-[30px] max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          {/* Title */}
          <p className="textAgrandir font-bold text-[16px] sm:text-[20px] lg:text-[24px] xl:text-[30px] w-full text-center p-1">
            Sponsors et partenaires
          </p>

          {/* Sponsor List */}
          <motion.div
            ref={ref}
            transition={{
              type: "spring",
              damping: 5,
              bounce: 0.6,
              duration: 0.9,
            }}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
          >
            {/* Sponsor List Component */}
            <SponsorList />

            {/* AUF Logo */}
            <div className="gap-5 sm:gap-10 xl:gap-[40px] xl:px-[220px] xl:justify-evenly max-w-[1440px] items-center flex flex-col justify-center xl:pt-10">
              <img
                src={aufLogo}
                alt="Logo AUF"
                className="h-[80px] sm:h-[90px] lg:h-[100px] xl:h-[100px]"
              />
            </div>
          </motion.div>
        </section>
      </div>
    </div>
  );
};

export default Home;
