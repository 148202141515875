import React, { useEffect, useState } from 'react';
import { FaAnglesLeft } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { firestoreDatabase } from '../../../../utils/firebase';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import UDocline from './UDocline';

const Docs = () => {

    const navigate = useNavigate();

    const [docs, setDocs] = useState([]);

    //read articles data from firebase
    useEffect(() => {
        const fetchDocs = async () => {
            const docsRef = query(collection(firestoreDatabase, 'docs'), orderBy('createdAt', 'desc'))
            onSnapshot(docsRef, (snapshot) => {
                setDocs(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        fetchDocs();
    }, []);

    return (
        <div className="flex flex-col">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Documentation</p>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

                <div className='bg-white flex flex-col gap-[5px] px-[60px] w-full'>

                    {docs.map((doc, index) => (
                        <div className=''>
                            <UDocline
                                key={index} {...doc}
                                id={doc.id}
                                title={doc.data.docName}
                                doc={doc.data.fileUrl}
                            />
                        </div>

                    ))}

                </div>

                <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/docs/add-doc"} className='bg-black w-fit text-white font-bold py-2 px-3 rounded-md'>Charger un document</Link>
                </div>


            </section>

        </div>
    )
}

export default Docs