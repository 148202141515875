const Sponsor = (props) => {
  const { sponsorName, sponsorLogotype, sponsorType } = props;
  return (
    <li className="xl:h-[180px] h-[150px] aspect-square p-4 shadow-custom-light hover:shadow-custom-light transition-scall duration-300 flex flex-row justify-center items-center list-none relative hover:scale-105">
      {sponsorType != "" && (
        <img
          src={sponsorType}
          alt={sponsorName}
          className="w-[20%] absolute right-2 top-2"
        />
      )}
      <img src={sponsorLogotype} alt={sponsorName} className="w-[80%]" />
    </li>
  );
};

export default Sponsor;
