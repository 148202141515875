import React, { useState } from 'react';
import contactbg from '../../assets/pmi/contact_bg.png';
import { BsCheck2Circle, BsTwitterX } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { firestoreDatabase } from '../../utils/firebase';
import Modal from '../../components/modal/Modal';
import { MdOutlineLocalPhone, MdOutlineMailOutline, MdOutlineSmsFailed } from 'react-icons/md';
import { FaAnglesLeft } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Contact = () => {

    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const sendMessage = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true); // Afficher le loader

            // Adding the article to Firebase Firestore
            await addDoc(collection(firestoreDatabase, "inbox"), {
                name: lastName + ' ' + firstName,
                email: email,
                phoneNumber: phoneNumber,
                message: message,
                sentAt: Timestamp.now(),
                read: false,
            });

            setIsSent(true);
            setIsLoading(false);
            setOpen(true);

            setLastName('');
            setFirstName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');

        } catch (error) {
            setIsSent(false);
            setIsLoading(false);
            setOpen(true);
        }
    };

    const navigate = useNavigate();

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col items-center w-full xl:gap-[100px] gap-[50px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='contact'></div>

            <section className="flex flex-col w-full xl:gap-[50px]" id=''>

                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-violet slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-violet xl:px-[60px] px-5 xl:w-[1440px] slide-left">CONTACT</p>
                    </div>
                </motion.div>
                <div className="xl:flex hidden xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-violet text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <section className="xl:flex hidden bg-white w-[1000px] mx-[10vw] p-[5px] shadow-lg rounded-md border-2" id=''>

                <div className="flex flex-col bg-violet w-1/3 p-[5px] shadow-md rounded-md">
                    <div className="flex flex-col items-start gap-10 bg-violet w-full h-full py-10 rounded-md bg-right-bottom bg-no-repeat"
                        style={{ backgroundImage: `url(${contactbg})` }}>

                        <p className="font-pressura text-[20px] text-white xl:pl-[15px]">Nous Contacter</p>

                        <div className="flex flex-col gap-2.5 text-white pl-[15px]">

                            <a href="mailto:pmitogo@pmi-togo.org" className="flex items-center gap-[5px] text-[14px] font-agrandir">
                                <MdOutlineMailOutline />
                                <p className="">pmitogo@pmi-togo.org</p>
                            </a>

                            <a href="tel:+22890857070" className="flex items-center gap-[5px] text-[14px] font-agrandir">
                                <MdOutlineLocalPhone />
                                <p className="">00228 90 85 70 70</p>
                            </a>

                        </div>

                        <div className="flex text-white xl:pl-[15px]">
                            <div className="flex gap-5">
                                <a href="https://www.linkedin.com/company/pmi-togo-chapter/" target="_blank" rel="noopener" className='text-2xl' >
                                    <FaLinkedinIn />
                                </a>

                                <a href="https://twitter.com/pmitogochapter" target="_blank" rel="noopener" className="">
                                    <BsTwitterX className='text-2xl' />
                                </a>

                                <a href="https://www.facebook.com/pmitogochapter" target="_blank" rel="noopener" className='text-2xl' >
                                    <FaFacebookF />
                                </a>

                                <a href="https://www.instagram.com/pmi_togo/" target="_blank" rel="noopener" className='text-2xl' >
                                    <FaInstagram />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="flex flex-col gap-5 bg-white p-10 w-2/3">
                    <p className="text-[14px]">Remplissez le formulaire et notre équipe vous <br /> répondra dans un délai de 24h.</p>

                    <form onSubmit={sendMessage} className='flex flex-col gap-5 w-full'>

                        <div className="flex justify-between items-center gap-[30px] w-full">

                            <div className="flex flex-col w-full font-agrandir">
                                <p className="text-[14px]">Nom</p>
                                <input
                                    type="text"
                                    className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                                    value={lastName} onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <p className="text-[14px] font-agrandir">Prénom(s)</p>
                                <input
                                    type="text"
                                    className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                                    value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </div>

                        </div>

                        <div className="flex justify-between items-center gap-[30px] w-full">

                            <div className="flex flex-col w-full font-agrandir">
                                <p className="text-[14px]">Email</p>
                                <input
                                    type="email"
                                    className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <p className="text-[14px] font-agrandir">Téléphone avec indicatif</p>
                                <input
                                    type="number"
                                    className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>

                        </div>

                        <div className="flex flex-col w-full">
                            <p className="text-[14px] font-agrandir">Message</p>
                            <textarea
                                className='border-2 h-[100px] p-2 outline-none rounded-md w-full shadow-md' cols="" rows="140"
                                value={message} onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </div>

                        <div className="flex justify-end">
                            <button className="buttonviolet w-[100px!important]">
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : (
                                    <p>Envoyer</p>
                                )}
                            </button>
                        </div>

                    </form>

                </div>

            </section>

            <section className="flex xl:hidden flex-col gap-[30px] bg-violet mx-5 pt-[10px] pb-[70px] px-[10px] rounded-md shadow-md bg-right-bottom bg-no-repeat"
                style={{ backgroundImage: `url(${contactbg})` }}>

                <div className="flex justify-between">
                    <div className="flex flex-col gap-[30px]">
                        <p className="font-pressura text-[20px] text-white">Nous Contacter</p>
                        <p className="text-[12px] text-white">Remplissez le formulaire et notre équipe vous répondra dans un délai de 24h.</p>

                    </div>
                </div>

                <form onSubmit={sendMessage} className='flex flex-col gap-5 w-full'>

                    <div className="flex flex-col w-full font-agrandir">
                        <p className="text-[14px] text-white">Nom</p>
                        <input
                            type="text"
                            className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                            value={lastName} onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex flex-col w-full font-agrandir">
                        <p className="text-[14px] text-white">Prénom(s)</p>
                        <input
                            type="text"
                            className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                            value={firstName} onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex flex-col w-full font-agrandir">
                        <p className="text-[14px] text-white">Email</p>
                        <input
                            type="text"
                            className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                            value={email} onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex flex-col w-full font-agrandir">
                        <p className="text-[14px] text-white">Téléphone avec indicatif</p>
                        <input
                            type="number"
                            className='border-2 h-10 p-2 outline-none rounded-md w-full shadow-md'
                            value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex flex-col w-full font-agrandir">
                        <p className="text-[14px] text-white">Message</p>
                        <textarea
                            className='border-2 h-[100px] p-2 outline-none rounded-md w-full shadow-md' cols="" rows="140"
                            value={message} onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex items-center justify-center">
                        <button className="buttonwhite w-[100px!important]">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Envoyer</p>
                            )}
                        </button>
                    </div>

                </form>

                <div className="flex flex-col gap-2.5 text-white pt-10">

                    <a href="mailto:pmitogo@pmi-togo.org" className="flex items-center gap-[5px] text-[14px] font-agrandir">
                        <MdOutlineMailOutline />
                        <p className="">pmitogo@pmi-togo.org</p>
                    </a>

                    <a href="tel:+22890857070" className="flex items-center gap-[5px] text-[14px] font-agrandir">
                        <MdOutlineLocalPhone />
                        <p className="">00228 90 85 70 70</p>
                    </a>

                </div>

                <div className="flex justify-between items-start text-white">
                    <div className="flex gap-3">
                        <a href="https://www.linkedin.com/company/pmi-togo-chapter/" target="_blank" rel="noopener" className='text-2xl' >
                            <FaLinkedinIn />
                        </a>

                        <a href="https://twitter.com/pmitogochapter" target="_blank" rel="noopener" className="text-2xl">
                            <BsTwitterX className='text-2xl' />
                        </a>

                        <a href="https://www.facebook.com/pmitogochapter" target="_blank" rel="noopener" className='text-2xl' >
                            <FaFacebookF />
                        </a>

                        <a href="https://www.instagram.com/pmi_togo/" target="_blank" rel="noopener" className='text-2xl' >
                            <FaInstagram />
                        </a>
                    </div>
                </div>

            </section>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            {isSent ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>Votre message a été envoyé !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}
                            <button
                                className='buttonviolet w-[50px!important]'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }
        </div>
    )
}

export default Contact