import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestoreDatabase } from '../../../../utils/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../../../components/modal/Modal';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdOutlineSmsFailed } from 'react-icons/md';
import { FaAnglesLeft } from 'react-icons/fa6';

const AddUser = () => {

    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleAddUser = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password, displayName);
            const user = userCredential.user;

            await setDoc(doc(firestoreDatabase, "users", user.uid), {
                email: email,
                displayName: displayName,
                role: role,
                autorisation: true,
                createdAt: new Date(),
            });

            setIsSent(true);
            setIsLoading(false);
            setOpen(true);
        } catch (error) {
            console.error('Erreur lors de la création de l\'utilisateur:', error.message);
            setIsSent(false);
            setIsLoading(false);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
            <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Utilisateurs</p>

            <div className="xl:flex hidden justify-start items-center">
                <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                    <FaAnglesLeft className='text-[10px]' />
                    Back
                </Link>
            </div>

            <div className='flex flex-col items-center justify-center'>
                <p className='font-bold text-violet'>Ajouter un utilisateur</p>

                <form onSubmit={handleAddUser} className='flex flex-col gap-5'>
                    <label>
                        Nom complet:
                        <input
                            className='outline-none border-2 px-4 py-2 rounded-md w-full font-agrandir'
                            type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} required />
                    </label>

                    <label>
                        Email:
                        <input
                            className='outline-none border-2 px-4 py-2 rounded-md w-full font-agrandir'
                            type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </label>

                    <label>
                        Mot de passe:
                        <input
                            className='outline-none border-2 px-4 py-2 rounded-md w-full font-agrandir'
                            type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </label>

                    <label>
                        Rôle:
                        <select
                            className='outline-none border-2 px-4 py-2 rounded-md w-full font-agrandir'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required>
                            <option >-Sélectionner le rôle-</option>
                            <option value="Rédacteur">Rédacteur</option>
                            <option value="Administrateur">Administrateur</option>
                        </select>
                    </label>

                    <div className="flex w-full justify-center items-center pt-10">
                        <button className="buttonviolet w-[100px!important]">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Ajouter</p>
                            )}
                        </button>
                    </div>

                </form>
            </div>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>

                            {isSent ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>Nouvel utilisateur ajouté !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}

                            <button
                                className='buttonviolet'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>

                        </div>
                    </center>
                </Modal>
            }

        </section>
    );
};

export default AddUser;
