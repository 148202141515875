import HomeEasyAccess from "../components/HomeEasyAccess/HomeEasyAccess";
import useHomeEasyAccessList from "../hooks/useHomeEasyAccessList";

const getSvgPath = (key) => {
  switch (key) {
    case 0:
      return "/COMMUNITY-CLR.svg";
      break;
    case 1:
      return "/COLLABORATION-CLR.svg";
      break;

    default:
      break;
  }
};

const HomeEasyAccessList = () => {
  const easyAccessList = useHomeEasyAccessList();

  const lastTwoElements = easyAccessList.slice(-2);

  return (
    <ul className="mx-auto px-[20px]">
      {lastTwoElements.map((easyAccess, index) => (
        <HomeEasyAccess
          key={index}
          sectionTitle={easyAccess.sectionTitle}
          briefContent={easyAccess.briefContent}
          sectionImg={easyAccess.sectionImg}
          borderTop={index !== 0}
          linkTo={easyAccess.linkTo}
          isImageOnLeft={index % 2 === 0}
          svgPath={getSvgPath(index)}
        />
      ))}
    </ul>
  );
};

export default HomeEasyAccessList;
