import NEUROTECHlogotype from "../assets/images/sponsor_logotypes/neurotech-logo.png";
import TOGOCOMlogotype from "../assets/images/sponsor_logotypes/togocom-logo.png";
import YASTOGOBLUElogotype from "../assets/images/sponsor_logotypes/yas-togo-blue-logo.svg";
import YASTOGOYELLOWlogotype from "../assets/images/sponsor_logotypes/yas-togo-yellow-logo.svg";
import CFAOlogotype from "../assets/images/sponsor_logotypes/cfao-logo.png";
import PBSlogotype from "../assets/images/sponsor_logotypes/pbs-logo.jpg";
import INOVACTlogotype from "../assets/images/sponsor_logotypes/inovact-logo.png";
import LECONOMISTElogotype from "../assets/images/sponsor_logotypes/leconomiste-logo.png";

import GoldCategory from "../assets/images/sponsorship_badges/gold-sponsor.png";
import SilverCategory from "../assets/images/sponsorship_badges/silver-sponsor.png";

const useSponsorList = () => {
  const sponsorList = [
    {
      sponsorName: "NEUROTECH",
      sponsorLogotype: NEUROTECHlogotype,
      sponsorType: GoldCategory,
    },
    {
      sponsorName: "YASTOGO",
      sponsorLogotype: YASTOGOYELLOWlogotype,
      sponsorType: SilverCategory,
    },
    {
      sponsorName: "CFAO",
      sponsorLogotype: CFAOlogotype,
      sponsorType: "",
    },
    {
      sponsorName: "PBS",
      sponsorLogotype: PBSlogotype,
      sponsorType: "",
    },
    {
      sponsorName: "INOVACT",
      sponsorLogotype: INOVACTlogotype,
      sponsorType: "",
    },
    {
      sponsorName: "LECONOMISTE",
      sponsorLogotype: LECONOMISTElogotype,
      sponsorType: "",
    },
  ];

  return sponsorList;
};

export default useSponsorList;
