import PMITogoPresidentImg from "../assets/images/EasyAccess/pmi-togo-president.jpg";
import MemberBenefitsImg from "../assets/images/members/av_three.jpg";
import EventsImg from "../assets/images/EasyAccess/our-events.jpg";

const useHomeEasyAccessList = () => {
  const easyAccessList = [
    {
      sectionTitle: "Qui Sommes-nous ?",
      briefContent: [
        "Le Chapitre PMI TOGO est la branche locale du Project Management Institute, organisation de renommée mondiale spécialisée dans la gestion de projets, de programmes et de portefeuilles comptants plus de 600.000 membres à travers le monde.",
      ],
      sectionImg: PMITogoPresidentImg,
      linkTo: "/we#we",
    },
    {
      sectionTitle: "Les Avantages",
      briefContent: [
        "En devenant membre du PMI, vous n'obtenez pas seulement des réductions sur les certifications et les événements ; vous rejoignez une communauté mondiale qui s'épanouit dans la connexion, le développement et le bien social.",
      ],
      sectionImg: MemberBenefitsImg,
      linkTo: "/members#advantages",
    },
    {
      sectionTitle: "Nos Evénements",
      briefContent: [
        "Le Chapitre PMI TOGO organise régulièrement des événements pour ses membres et les professionnels de la gestion de projets. Ces événements sont l'occasion de se former, de réseauter et de partager des expériences.",
      ],
      sectionImg: EventsImg,
      linkTo: "/events#localevents",
    },
  ];

  return easyAccessList;
};

export default useHomeEasyAccessList;
