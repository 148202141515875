import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Timestamp, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaAnglesLeft } from 'react-icons/fa6';
import { firestoreDatabase } from '../../../../utils/firebase';
import { MdOutlineSmsFailed } from 'react-icons/md';
import { BsCheck2Circle } from 'react-icons/bs';
import Modal from '../../../../components/modal/Modal';

const JobView = () => {

    const { id } = useParams();
    const [job, setJob] = useState([]);
    const [isVisible, setIsVisible] = useState([]);
    const [isDone, setIsDone] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const readThisJobData = async () => {
            const docRef = doc(firestoreDatabase, 'jobs', id);
            const docSnap = await getDoc(docRef);
            setJob(docSnap.data());
            setIsVisible(docSnap.data().isVisible);
        };
        readThisJobData();
    }, [id]);


    //Archiver un event
    const [isArchiving, setIsArchiving] = useState(false);

    const archivateEvent = async (event) => {
        event.preventDefault();
        setIsArchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'jobs', id);

        try {

            await updateDoc(articleDocRef, {
                isVisible: false,
                lastModif: Timestamp.now(),
            });

            setIsArchiving(false);
            navigate(-1);
        } catch (error) {
            setIsArchiving(false);
            console.error("Error updating article: ", error);
        }
    };

    //Désarchiver un event
    const [isDisarchiving, setIsDisarchiving] = useState(false);

    const desarchivateEvent = async (event) => {
        event.preventDefault();
        setIsDisarchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'jobs', id);

        try {

            await updateDoc(articleDocRef, {
                isVisible: true,
                lastModif: Timestamp.now(),
            });

            setIsDisarchiving(false);
            navigate(-1);
        } catch (error) {
            setIsDisarchiving(false);

        }
    };

    const deleteEvent = async () => {
        try {

            // Accédez au document dans Firestore
            const docRef = doc(firestoreDatabase, 'jobs', id);

            // Supprimez le document
            await deleteDoc(docRef);
            setIsDone(true);
            setOpen(true);

            // Vous pouvez également effectuer d'autres actions après la suppression, comme rediriger l'utilisateur ou afficher un message de confirmation.
        } catch (error) {
            setIsDone(false);
            setOpen(true);
            // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    }


    return (
        <div className="grid justify-center w-full">
            <div className="flex flex-col gap-5 w-full pt-5 xl:pb-[100px] pb-[80px] max-w-[1440px]">

                <div className="flex justify-start items-center xl:px-[160px] px-[20px]" id='thisevent'>
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-black text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

                <section className="flex flex-col items-center gap-5">

                    <div className="w-full flex flex-col xl:gap-16 gap-5 rounded-lg eventscroll px-[20px] xl:px-[160px]">

                        <div className="flex xl:flex-row flex-col items-center justify-start xl:gap-10 gap-10">
                            <img src={job.jobCover} alt="" className='w-auto object-fill h-[auto] max-h-[300px] rounded-xl shadow-xl' />

                            <div className="flex flex-col">

                                <p className="font-pressura text-violet xl:text-[36px] uppercase text-[18px]">{job.jobtitle}</p>

                            </div>

                        </div>

                        <p className="font-agrandir text-black text-[14px] text-justify" dangerouslySetInnerHTML={{ __html: job?.content }} />

                    </div>

                    <div className="w-full flex justify-center items-center mt-5">
                        <Link to={job.directLink} target="_blank" rel="noopener" className="buttonviolet">Postuler</Link>
                    </div>
                </section>

                <div className="flex gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/jobs/edit/" + id} className='buttonblack'>Modifier</Link>

                    {isVisible ? (
                        <button
                            onClick={archivateEvent}
                            className='buttonblack'
                            type='button'
                        >
                            {isArchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Archiver</p>
                            )}
                        </button>

                    ) : (

                        <button
                            onClick={desarchivateEvent}
                            className='buttonblack'
                            type='button'
                        >
                            {isDisarchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Désarchiver</p>
                            )}
                        </button>
                    )}

                    {isVisible === false && (
                        <button
                            className='buttonblack'
                            type='button'
                            onClick={deleteEvent}
                        >
                            Supprimer
                        </button>
                    )}
                </div>

            </div>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            {isDone ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>L'offre a été supprimé !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}
                            <button
                                className='buttonviolet w-[50px!important]'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }
        </div>)
}

export default JobView