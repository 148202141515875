import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { firestoreDatabase } from '../../../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaAnglesLeft } from 'react-icons/fa6';

const Message = () => {

    const { id } = useParams();
    const [thisMessage, setThisMessage] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const readThiseventData = async () => {
            const docRef = doc(firestoreDatabase, 'inbox', id);
            const docSnap = await getDoc(docRef);
            setThisMessage(docSnap.data());
        };
        readThiseventData();
    }, [id]);

    return (

        <div className="flex flex-col">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">

                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Messagerie</p>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

                <div className="flex flex-col px-[2vw]">
                    <p className="font-agrandir xl:text-[20px] text-[14px] text-violet">Message de : {thisMessage?.name}</p>

                    <div className='bg-white flex flex-col gap-[5px] w-full'>

                        <div className="border-b-2">
                            <div className="sender">Adresse Email: {thisMessage?.email}</div>
                            <div className="timestamp">Téléphone: {thisMessage?.phoneNumber}</div>
                        </div>

                        <div className="pt-2.5">{thisMessage?.message}</div>

                    </div>
                </div>

                <div className="flex gap-5 justify-end items-center w-full">
                    <a href={`mailto:${thisMessage?.email}`} className='buttonviolet' >Répondre par mail</a>
                </div>



            </section>

        </div>

    )
}

export default Message