import React, { useEffect, useState } from 'react';
import './newarticles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer les styles
import { Timestamp, collection, doc, getDoc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsCheck2Circle } from 'react-icons/bs';
import { firestoreDatabase, storage } from '../../../../utils/firebase';
import Modal from '../../../../components/modal/Modal';
import { FaAnglesLeft } from 'react-icons/fa6';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import RubriqueOption from './rubrique/RubriqueOption';

const quillModules = {
    toolbar: [
        [{ 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

function EditArticle() {

    /*Récupérer les données de l'article */

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const { id } = useParams();
    const [author, setAuthor] = useState('');
    const [cover, setCover] = useState('');
    const [rubrique, setRubrique] = useState('');

    const handleCover = (event) => {
        setCover(event.target.files[0]);
    };

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const articleRef = doc(firestoreDatabase, 'articles', id);
                const snapshot = await getDoc(articleRef);
                const articleData = snapshot.data();

                if (articleData) {
                    setTitle(articleData.title);
                    setContent(articleData.content);
                    setCover(articleData.coverUrl);
                    setAuthor(articleData.author);
                    setRubrique(articleData.rubrique)
                }
            } catch (error) {
                console.error("Error fetching article: ", error);
            }
        };

        fetchArticle();
    }, [id]);

    //read rubriques data
    const [rubriqueOptions, setRubriqueOptions] = useState([])
    useEffect(() => {
        const rubriqueOptionRef = query(collection(firestoreDatabase, 'categories'), orderBy('nom', 'asc'))
        onSnapshot(rubriqueOptionRef, (snapshot) => {
            setRubriqueOptions(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }, [])

    const updateArticle = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Afficher le loader

        // Télécharger la photo de profil sur Firebase Storage
        const fileRef = ref(storage, `article_covers/${cover.name}`);
        await uploadBytes(fileRef, cover);

        // Récupérer l'URL de téléchargement de la photo de profil
        const coverUrl = await getDownloadURL(fileRef);

        const articleDocRef = doc(firestoreDatabase, 'articles', id)
        try {
            await updateDoc(articleDocRef, {
                title: title,
                author: author,
                coverUrl: coverUrl,
                rubrique: rubrique,
                content: content,
                lastModif: Timestamp.now()
            })
            setOpen(true)
            setIsLoading(false);
        }
        catch (error) {
            console.error("Error updating article: ", error);
            setIsLoading(false);
        }
    };

    const handleChange = (newValue) => {
        setContent(newValue);
    };

    const handleClose = () => {
        setOpen({ edit: false });
        navigate(-1);
    }

    return (

        <div id='newArticle' className='px-[60px]'>

            <section className="flex pb-[30px]" id='blog'>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className='flex justify-center w-full'>
                <form className='col-md-8'
                    onSubmit={updateArticle}
                >

                    <div className='form-ctrl'>
                        <label><p>Titre</p></label>
                        <input
                            className='outline-none border-2 px-4 py-2 rounded-md w-full'
                            type="text"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            required
                        />
                    </div>

                    <div className='form-ctrl'>
                        <label><p>Auteur</p></label>
                        <input type="text"
                            className='outline-none border-2 px-4 py-2 rounded-md w-full'
                            value={author}
                            onChange={(event) => setAuthor(event.target.value)}
                            required
                        />
                    </div>

                    <img src={cover} alt="" className='w-auto object-fill h-[auto] max-h-[200px] rounded-xl shadow-xl mb-7' />

                    <div className='form-ctrl'>
                        <label><p className=''>Photo de couverture</p></label>
                        <input type="file"
                            onChange={handleCover}
                            required
                        />
                    </div>

                    <div className='form-ctrl'>
                        <label><p className=''>Catégorie</p></label>
                        <select
                            value={rubrique}
                            onChange={(e) => setRubrique(e.target.value)}
                            required>
                            <option value="" selected>...</option>
                            {rubriqueOptions.map((rubriqueOption) => {
                                return (
                                    <RubriqueOption
                                        id={rubriqueOption.id}
                                        key={rubriqueOption.id}
                                        nom={rubriqueOption.data.nom}
                                    />
                                )
                            })}
                        </select>
                    </div>

                    <div className='form-ctrl'>
                        <label>Modifiez votre article</label>
                        <ReactQuill
                            value={content}
                            onChange={handleChange}
                            modules={quillModules}
                            formats={quillFormats}
                        />
                    </div>

                    <div className='btn-ctrl'>
                        <button className="buttonviolet w-[200px!important]">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Publier les modifications</p>
                            )}
                        </button>
                    </div>

                </form>

            </div>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            <BsCheck2Circle className='text-green-700 text-[50px]' />

                            <p>Votre article a été modifié !</p>

                            <button
                                className='buttonviolet'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }

        </div>

    )
}

export default EditArticle