import { Link } from "react-router-dom";

const HomeEasyAccess = (props) => {
  const {
    sectionTitle,
    briefContent,
    sectionImg,
    linkTo,
    isImageOnLeft,
    svgPath,
  } = props;
  return (
    <div className="">
      <li className="flex flex-col lg:flex-row py-8 gap-4 lg:gap-12">
        {/* Image on the left or right based on `isImageOnLeft` */}
        <div
          className={`w-full lg:w-1/3 aspect-video ${
            isImageOnLeft ? "order-1" : "lg:order-3"
          }`}
        >
          <img
            src={sectionImg}
            alt={sectionTitle}
            className="object-cover w-full h-full"
          />
        </div>

        {/* Title and description */}
        <div className="flex flex-col gap-4 w-full lg:w-2/3 order-2">
          {/* Title */}
          <div className="flex flex-col lg:flex-row items-center gap-4 mb-4">
            <img
              src={svgPath} // Update this path
              alt="Icon"
              className="h-[80px] w-[80px] lg:h-[120px] lg:w-[120px] text-[#ff610f]" // Adjust size for mobile and desktop
            />
            <h2 className="textAgrandir font-bold text-[24px] sm:text-[30px] lg:text-[37px] text-center lg:text-left">
              {sectionTitle}
            </h2>
          </div>

          {/* Description and "Voir Plus" link */}
          <div className="lg:ml-20 flex flex-col items-center lg:items-start gap-4">
            <p className="textSpaceMono text-[14px] sm:text-[16px] text-center lg:text-left">
              {briefContent}
            </p>
            <Link to={linkTo}>
              <button className="headerBtnViolet mt-2">
                LIRE <span className="px-0.5"></span> PLUS
              </button>
            </Link>
          </div>
        </div>
      </li>
    </div>
  );
};

export default HomeEasyAccess;
