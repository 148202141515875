import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaAnglesLeft } from 'react-icons/fa6';


const PrivacyPolicy = () => {

    const navigate = useNavigate();

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });
    return (
        <div className="flex flex-col gap-[50px] pb-[100px]">

            <div className="xl:h-[90px] xl:block hidden" id="policy"></div>

            <section className="flex flex-col xl:gap-[50px]" id=''>
                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-black slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-black xl:px-[60px] px-5 xl:w-[1440px] slide-left">Mentions légales et politique de confidentialité</p>
                    </div>
                </motion.div>
                <div className="flex xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-black text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <section className=" xl:px-[60px] px-5">
                <div class="flex flex-col gap-5">
                    <ul>
                        <li style={{ TextAlign: "justify" }}><strong>CONDITIONS D’UTILISATION DU SITE WEB</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>En visitant et en utilisant ce site Web, vous acceptez de respecter les conditions d’utilisation qui peuvent être modifiées de temps à autre sans avis en apportant des changements à ce document. Assurez-vous de lire et de comprendre les conditions. Chaque fois que vous visitez ce site Web, vous êtes supposé avoir lu et accepté les conditions d’utilisation. La visite ou l’utilisation continue des services offerts par l’entremise de ce site Web signifie que vous acceptez ces conditions d’utilisation y compris tous les changements. Nous afficherons ces changements sur le site Web. Veuillez vérifier ces changements et mises à jour.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>LIMITATION DE RESPONSABILITÉ</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>PMI-Togo ne peut garantir à 100% la sécurité des données transmises sur Internet. De plus, PMI-Togo ne saurait garantir l’exactitude, la complétude et l’actualité des informations diffusées sur son site. Vous reconnaissez donc utiliser ces informations sous votre responsabilité exclusive. Nous mettons tout en œuvre&nbsp; pour garantir des informations disponibles et vérifiées mais ne saurait être tenu pour responsable des erreurs ou de la présence de virus sur son site.</p>
                    <p style={{ TextAlign: "justify" }}>Les renseignements que contiennent ce site Web sont réputés être exacts et fiables au moment de leur publication sur le site Web. Toutefois, nous ne pouvons garantir qu’ils seront toujours exacts, fiables, complets ou mis à jour. Ces renseignements peuvent être modifiés. Nous n’assumons aucune responsabilité pour toute perte subie en raison de l’utilisation de ce site Web ou de l’impossibilité d’utiliser ce site Web. Sauf indication contraire, les renseignements ne sont pas vérifiés et sont fournis «&nbsp;tels quels&nbsp;». Nous ne prétendons pas que le site Web sera ininterrompu ou dépourvu d’erreurs, que ces défaillances seront corrigées, que le site ou le serveur qui l’offre ne contient pas de virus ou d’autres éléments nuisibles.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>INFORMATIONS SUR LES COOKIES</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>L'utilisateur est informé que lors de ses visites sur le site un "cookie " peut s'installer automatiquement sur son logiciel de navigation. Un "cookie" est un bloc de données qui ne permet pas d'identifier l'utilisateur mais qui sert à enregistrer des informations relatives à la navigation de celui-ci sur le site.</p>
                    <p style={{ TextAlign: "justify" }}>Ce procédé est destiné à permettre à la société PMI-Togo de suivre les internautes au fil de leur navigation sur le site <span className='underline'><span className='text-[#0089bd]'><a className='underline' href="http://www.pmi-togo.org">www.pmi-togo.org</a></span></span> afin de pouvoir mieux répondre à leurs attentes et leurs besoins.</p>
                    <p style={{ TextAlign: "justify" }}>L'utilisateur peut cependant s'opposer à la mise en place de "cookies" en désactivant cette option dans les paramètres de son navigateur.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>DROITS D’AUTEUR</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>Tous les renseignements contenus dans le site Web de PMI-Togo sont protégés par les lois sur les droits d’auteur applicables. Il est interdit de copier, distribuer, modifier, afficher ou utiliser tout texte, graphique, vidéo, code de logiciel ou conception interface-utilisateur ou logos. Toute utilisation ou exploitation non autorisée de ces marques et noms est strictement interdite.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>LIENS HYPERTEXTES</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>Les références faites sur ce site Web aux produits ou services de tierces parties ne signifient pas que PMI-Togo endosse ou approuve ces produits ou services, et ne sont là que par commodité. PMI-Togo n’endosse pas ni n’approuve les liens à d’autres sites Web, les tierces parties, ou leurs produits et services, ni n’est responsable de toute incompatibilité, inconvenance, contamination par virus ou vers informatiques, cheval de Troie ou tout autre agent destructeur en provenance de ces sites. Les liens à d’autres sites sont faits à vos propres risques et PMI-Togo n’est tenue responsable d’aucun dommage causé par les liens.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>COURRIER ELECTRONIQUE SUR INTERNET</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>La confidentialité et la sécurité des transmissions de courriel par Internet ne sont pas garanties. Les communications par courriel ne sont pas confidentielles et peuvent être interceptées ou perdues, ou peuvent également être modifiées. PMI-Togo n’est pas responsable de torts ou dommages que vous ou une autre personne pourriez subir au cours de la transmission d’un courriel contenant des renseignements personnels ou confidentiels par Internet à PMI-Togo, ou d’un courriel que PMI-Togo vous envoie à votre demande.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>UTILISATION DU SITE WEB</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>PMI-Togo n’est tenu responsable d’aucun dommage (direct, indirect, spécial, économique, consécutif, exemplaire ou punitif) survenu suite à l’utilisation ou l’incapacité d’utiliser ce site Web, son contenu, ou toute action ou décision prise par vous ou une autre personne en fonction du site Web, ou de son contenu, ou de toute utilisation ou reproduction non autorisée du site Web ou de son contenu.</p>

                    <ul style={{ TextAlign: "justify" }}>
                        <li><strong>DÉCLARATION DE CONFIDENTIALITÉ</strong></li>
                    </ul>
                    <p style={{ TextAlign: "justify" }}>PMI-Togo s’engage à protéger la vie privée de ses clients. La protection des renseignements personnels est essentielle à la confiance des clients et à l’intégrité de la marque. Tous nos employés sont tenus de préserver la confidentialité de tous les renseignements personnels auxquels ils ont accès. La conduite de chaque employé de PMI-Togo est régie par le code d’éthique et la Politique de confidentialité de PMI-Togo, lesquels prévoit des dispositions concernant les renseignements personnels et confidentiels. Tous les employés doivent confirmer leur adhésion au Code d’éthique et à la Politique de confidentialité lors de l’embauche et une fois par année au début de l’année.</p></div>
            </section>
        </div>
    )
}

export default PrivacyPolicy