import useSponsorList from "../../hooks/useSponsorList";
import Sponsor from "./Sponsor";

const SponsorList = () => {
  const sponsors = useSponsorList();
  return (
    <ul className="flex flex-wrap xl:gap-8 gap-4 xl:justify-between justify-center mt-8 mb-4">
      {sponsors.map((sponsor, index) => (
        <Sponsor
          key={index}
          sponsorName={sponsor.sponsorName}
          sponsorLogotype={sponsor.sponsorLogotype}
          sponsorType={sponsor.sponsorType}
        />
      ))}
    </ul>
  );
};

export default SponsorList;
