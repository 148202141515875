import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { FaAnglesLeft } from 'react-icons/fa6'
import { firestoreDatabase } from '../../../../utils/firebase'

const JobsApp = () => {

    const [jobOffers, setJobOffers] = useState([]);

    //read jobs data from firebase
    useEffect(() => {
        const readJobOffersData = async () => {
            const jobsRef = query(collection(firestoreDatabase, 'jobs'), orderBy('createdAt', 'desc'), where('isVisible', '==', true))
            onSnapshot(jobsRef, (snapshot) => {
                setJobOffers(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readJobOffersData();
    }, []);

    const navigate = useNavigate();

    return (
        <div className="flex justify-between">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Offres d'emploi</p>


                <section className="flex pb-[30px]" id='blog'>

                    <div className="xl:flex hidden justify-start items-center">
                        <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                            <FaAnglesLeft className='text-[10px]' />
                            Back
                        </Link>
                    </div>

                </section>
                <div className="flex flex-col justify-center items-center gap-[30px] px-[50px]">
                    {jobOffers.length > 0 ?
                        jobOffers.map((job, index) => (
                            <div key={index} className='flex justify-between items-center px-10 py-5 w-full rounded-md border-2 hover:border-lightgrey bg-lightgrey hover:bg-white'>
                                <p className="flex items-center text-agrandir text-black">{job.data.jobtitle}</p>

                                <Link to={"/dashboard/jobs/jobview/" + job.id + "#jobdetails"} className='buttonblack'>voir</Link>
                            </div>
                        ))
                        :
                        <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                            <div className="flex flex-col">
                                Aucune offre d'emploi disponible !
                                <span className="h-1 w-10 bg-tangerine" />
                            </div>
                        </div>
                    }
                </div>

                <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/jobs/new-joboffer"} className='buttonblack'>Nouvelle offre</Link>
                    <Link to={"/dashboard/jobs/archives"} className='buttonblack'>Archives</Link>
                </div>

            </section>
        </div>
    )
}

export default JobsApp