import React from 'react';
import { Outlet } from 'react-router-dom';
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";

const AuthLayout = () => {
    return (
        <div className='flex w-screen h-screen justify-center items-center'>

            <div className="xl:flex hidden justify-center items-center shadow-xl rounded-xl min-w-[300px]">
                <Outlet />
            </div>

            <div className="xl:hidden flex justify-center items-center border-2 shadow-xl rounded-xl mx-[60px]">
                <div className="flex flex-col gap-2.5 justify-center items-center w-full px-10 py-20">
                    <img src={logo} className='h-[60px]' alt="PMI Chapter Togo" />

                    <p className="font-pressura text-[14px] text-center">Cette page est accessible uniquement via un ordinateur !</p>

                </div>
            </div>
        </div>
    )
}

export default AuthLayout