import React from 'react';
import './localevent.css'
import { Link } from 'react-router-dom';
import { CiLocationOn } from 'react-icons/ci';
import { IoMdCalendar } from 'react-icons/io';

const LocalEventCard = ({ id, coverImg, title, date, location, mapLink }) => {

    return (
        <>

            <div className="xl:h-[310px] xl:max-w-[unset] max-w-[450px] min-w-[320px] flex xl:flex-row flex-col xl:justify-center rounded-2xl">

                <img src={coverImg} alt="" className="block xl:rounded-l-2xl rounded-t-2xl" />

                <div className="flex flex-col justify-center gap-2.5 h-full w-full xl:rounded-r-2xl shadow-xl xl:max-w-[600px] xl:px-5 py-5 px-5 bg-violet xl:rounded-none rounded-b-2xl">

                    <p className="xl:text-[24px] text-[16px] font-bold text-white uppercase">{title}</p>

                    <div className="flex xl:flow-row flex-col gap-2.5 text-white text-[14px] font-pressura">

                        <div className="flex items-center gap-[5px] text-white font-pressura">
                            <IoMdCalendar />
                            <span className='xl:text-[14px] text-[14px]'>{date}</span>
                        </div>

                        <div className="flex items-center gap-[5px]">
                            <CiLocationOn />
                            <a href={location} className='xl:text-[14px] text-[14px]'>{location}</a>
                        </div>

                    </div>

                    <Link to={"/events/event/" + id + "#thisevent"} className="buttonwhite">En Savoir Plus</Link>

                </div>

            </div>

        </>

    )
}

export default LocalEventCard