import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EventArticleCard from './EventArticleCard'
import { fr } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { firestoreDatabase } from '../../../../utils/firebase'
import { FaAnglesLeft } from 'react-icons/fa6'

const EventsBlog = () => {

  const [events, setEvents] = useState([]);

  //read event data from firebase
  useEffect(() => {
    const readEventsData = async () => {
      const eventsRef = query(collection(firestoreDatabase, 'events'), orderBy('createdAt', 'desc'), where('isVisible', '==', true))
      onSnapshot(eventsRef, (snapshot) => {
        setEvents(snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })
    };
    readEventsData();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="flex justify-between">

      <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
        <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Evènements</p>


        <section className="flex pb-[30px]" id='blog'>

          <div className="xl:flex hidden justify-start items-center">
            <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
              <FaAnglesLeft className='text-[10px]' />
              Back
            </Link>
          </div>

        </section>
        <div className="flex flex-col justify-center items-center gap-[30px] px-[50px]">
          {events.length > 0 ?
            events.map((event, index) => (
              <EventArticleCard
                key={index} {...event}
                id={event.id}
                coverImg={event.data.coverUrl}
                title={event.data.eventName}
                date={format(parseISO(event.data.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                time={event.data.time}
              />
            ))
            :
            <div className="flex justify-center w-full font-agrandir text-[18px]">
              <div className="flex flex-col">
                Vous n'avez publié aucun évènement !
                <span className="h-1 w-10 bg-tangerine" />
              </div>
            </div>
          }
        </div>

        <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
          <Link to={"/dashboard/eventapp/new-event"} className='buttonblack'>Nouvel Evènement</Link>
          <Link to={"/dashboard/eventapp/archives"} className='buttonblack'>Archives</Link>
        </div>

      </section>
    </div>
  )
}

export default EventsBlog