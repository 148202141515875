import React, { useEffect } from "react";
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";
import { Link, useLocation } from "react-router-dom";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdOutlineLocalPhone, MdOutlineMailOutline } from "react-icons/md";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    // Faire défiler vers l'élément avec l'ID correspondant au hachage dans l'URL
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <footer className="bg-lightgrey flex flex-col items-center pt-[30px] md:gap-20 gap-14 px-5">
      {/* Web footer */}
      <div className="flex flex-col md:grid md:grid-cols-2 xl:flex xl:flex-row justify-between px-[20px] xl:px-0 h-auto xl:h-[220px] max-w-[1200px] w-full mx-auto py-8 xl:py-0 gap-8 md:gap-6 xl:gap-0">
        {/* Logo and Contact Info */}
        <div className="flex flex-col gap-[30px] items-center md:items-start">
          <Link to={"/#hero"}>
            <img
              src={logo}
              className="h-[60px] xl:h-[80px]"
              alt="PMI Chapter Togo"
            />
          </Link>
          <div className="flex flex-col gap-2.5">
            <a
              href="mailto:pmitogo@pmi-togo.org"
              className="flex items-center gap-[5px] hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
            >
              <MdOutlineMailOutline />
              <p className="footerText">pmitogo@pmi-togo.org</p>
            </a>

            <a
              href="tel:+22890857070"
              className="flex items-center gap-[5px] hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
            >
              <MdOutlineLocalPhone />
              <p className="footerText">00228 90 85 70 70</p>
            </a>
          </div>
        </div>

        {/* Quick Links - Who We Are */}
        <div className="flex flex-col gap-[15px] items-center md:items-start footerText">
          <p>QUI SOMMES NOUS ?</p>
          <Link
            to="/we#we"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Présentation - PMI TOGO
          </Link>
          <Link
            to="/we#headmember"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Membres dirigeants
          </Link>
          <Link
            to="/events#evenements"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Evènements
          </Link>
          <Link
            to="/blog#blog"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Publications
          </Link>
          <Link
            to="/devpro#prodev"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Carrières
          </Link>
        </div>

        {/* Quick Links - PMI TOGO CHAPTER */}
        <div className="flex flex-col gap-[15px] items-center md:items-start footerText">
          <p>PMI TOGO CHAPTER</p>
          <Link
            to="/#hero"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Accueil
          </Link>
          <Link
            to="/privacy-policy#policy"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Mentions légales & <br /> politique de confidentialité
          </Link>
          <Link
            to="/site-map#plan"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Plan du site
          </Link>
          <Link
            to="/contact#contact"
            className="hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one"
          >
            Contact
          </Link>
        </div>

        {/* Social Media Links */}
        <div className="flex flex-col gap-[15px] items-center md:items-start">
          <p className="flex flex-col footerText">
            SUIVEZ NOUS
            <span className="h-1 w-10 bg-tangerine mx-auto md:mx-0" />
          </p>
          <div className="flex gap-5">
            <a
              href="https://www.linkedin.com/company/pmi-togo-chapter/"
              target="_blank"
              rel="noreferrer"
              className="text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://twitter.com/pmitogochapter"
              target="_blank"
              rel="noreferrer"
              className="text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one"
            >
              <BsTwitterX />
            </a>
            <a
              href="https://www.facebook.com/pmitogochapter"
              target="_blank"
              rel="noreferrer"
              className="text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/pmi_togo/"
              target="_blank"
              rel="noreferrer"
              className="text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      <div className="flex max-w-[1200px]  w-full">
        <div className="flex column justify-center border-t-2 xl:max-w-[1200px] w-full">
          <p className="footerBottomText text-grey mx-auto w-full xl:text-[14px] text-[10px] xl:py-[30px] py-[10px] xl:text-start text-center">
            ©2025 PMI Chapitre Togo . Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
