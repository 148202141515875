import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";
import { auth, firestoreDatabase } from '../../utils/firebase';
import { signOut } from 'firebase/auth';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CiPower } from 'react-icons/ci';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Modal from '../../components/modal/Modal';


const DashboardLayout = () => {

  const activeSidenavLinkCss = 'bg-violet flex justify-start items-center gap-[10px] p-4 cursor-pointer text-white text-[14px] font-bold rounded w-[80%]';
  const sidenavLinkCss = 'flex justify-start items-center gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[80%] hover:bg-grey';

  const sidebarList = [
    {
      label: 'Dashboard',
      link: '/dashboard/',
    },
    {
      label: 'Publier un article',
      link: '/dashboard/blogapp/new-article',
    },
    {
      label: 'Publier un évènement',
      link: '/dashboard/eventapp/new-event',
    },
    {
      label: 'Publier un document',
      link: '/dashboard/docs/add-doc/',
    },
    {
      label: 'Publier une offre d\'emploi',
      link: '/dashboard/jobs/new-joboffer/',
    },

  ];

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleProfileBtn = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {

    try {
      await signOut(auth);
    } catch (error) {
      // Gérer les erreurs, si nécessaire
    }
  };

  const user = auth.currentUser;

  //Récupérer les données de l'utilisateur
  const [userData, setUserData] = useState(null);

  const [modalOpen, setModelOpen] = useState(false);

  const handleClose = () => {
    setModelOpen(false);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      const q = query(collection(firestoreDatabase, "users"),
        where("email", "==", user.email))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserData(doc.data());
      })
    };
    fetchUserData();

    //Déconnecter l'utilisateur après 30 minutes d'inactivité
    if (user) {
      const disconnectTimeOut = async () => {
        let timeoutId;

        function inactivityCallback() {
          signOut(auth);
          navigate("/auth");
        }

        function resetTimer() {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(inactivityCallback, 30 * 60 * 1000); // 30 * 60 * 1000 = 30 minutes
        }

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
      }
      disconnectTimeOut();
    }
  }, [user]);


  const [countdown, setCountdown] = useState(7); // Initialiser le décompte à 7 secondes

  const updateCountdown = () => {
    // Décrémenter le décompte
    setCountdown(prevCountdown => prevCountdown - 1);
  };

  useEffect(() => {
    // Vérifier si userData?.autorisation est false avant d'initialiser le minuteur
    if (userData?.autorisation === false) {
      setModelOpen(true);
      const timerInterval = setInterval(updateCountdown, 1000);

      // Nettoyer le minuteur lorsque le composant est démonté ou lorsque l'autorisation devient true
      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [userData?.autorisation]);

  // Effectuer une action lorsque le décompte atteint zéro
  useEffect(() => {
    if (countdown === 0) {
      // Exécuter la fonction de déconnexion après le décompte
      handleClose();
      signOut(auth);
    }
  }, [countdown]);


  return (
    <>
      <div className='h-screen w-screen xl:flex hidden'>

        <section className={`bg-lightgrey h-full xl:min-w-[250px] min-w-[250px] max-w-[80%] flex flex-col xl:flex z-50 absolute xl:relative`}>

          <Link to={'/dashboard/'}>
            <img src={logo} alt="" className="my-[30px] mx-5 h-[80px]" />
          </Link>

          <div className={`w-full h-full flex overflow-y-auto scrollBar`}>

            <div className={`h-full w-full flex flex-col gap-5 items-center select-none font-agrandir`}>

              {sidebarList.map((list, idx) => (
                <NavLink
                  key={idx}
                  to={list.link}
                  className={({ isActive }) =>
                    isActive ? activeSidenavLinkCss : sidenavLinkCss
                  }
                  end
                >
                  {list.label}
                </NavLink>
              ))}

            </div>

          </div>

        </section>

        <section className="flex flex-col w-full overflow-y-scroll">
          <div className="flex justify-end p-5 w-full">
            <div className='flex flex-col items-end gap-[50px] fixed select-none font-agrandir'>

              <div className="flex justify-center items-center gap-[15px] rounded-md w-fit h-[50px] py-1 px-3 cursor-pointer bg-tangerine" onClick={toggleProfileBtn}>
                <div className="flex items-center gap-[15px]">

                  {userData?.displayName && (
                    <p className="text-white font-bold">{userData?.displayName}</p>
                  )}

                </div>
                <div className="text-white">
                  {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>

              </div>

              {isOpen && (
                <div className="absolute top-[50px] flex flex-col w-max bg-white border-2 shadow-xl rounded-b-xl">
                  <div onClick={handleSignOut} className="flex items-center gap-3 py-[10px] px-[15px] text-black border-b-2 hover:bg-lightgrey cursor-pointer"><CiPower /> Déconnexion</div>
                </div>
              )}

            </div>
          </div>

          <div className="px-5 py-10">
            <Outlet />
          </div>

        </section>

      </div>

      <div className="xl:hidden flex justify-center items-center h-screen w-screen">
        <div className="xl:hidden flex justify-center items-center border-2 shadow-xl rounded-xl mx-[60px]">
          <div className="flex flex-col gap-2.5 justify-center items-center w-full px-10 py-20">
            <img src={logo} className='h-[60px]' alt="PMI Chapter Togo" />

            <p className="font-pressura text-[14px] text-center">Cette page est accessible uniquement via un ordinateur !</p>

          </div>
        </div>
      </div>

      {modalOpen &&
        <Modal isOpen={modalOpen} onClose={handleClose}>
          <center>
            <div className='flex flex-col gap-4 items-center justify-center'>

              <p>Vous n'avez pas accès à ce site, <br /> vous allez être déconnecté !</p>
              <p className="text-red-700 text-[24px]">{countdown}</p>

            </div>
          </center>
        </Modal>
      }

    </>
  )
}

export default DashboardLayout