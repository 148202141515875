import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../../utils/firebase';
import { FaAnglesLeft } from 'react-icons/fa6';

const JobDetail = () => {

  const { id } = useParams();
  const [job, setJob] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const readThisJobData = async () => {
      const docRef = doc(firestoreDatabase, 'jobs', id);
      const docSnap = await getDoc(docRef);
      setJob(docSnap.data());
    };
    readThisJobData();
  }, [id]);

  return (
    <div className="grid justify-center w-full">

      <div className="xl:h-[140px] xl:block hidden" id='jobdetails'></div>

      <div className="flex flex-col gap-5 w-full pt-5 xl:pb-[100px] pb-[80px] max-w-[1440px]">

        <div className="flex justify-start items-center xl:px-[160px] px-[20px]" id=''>
          <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-black text-[14px]">
            <FaAnglesLeft className='text-[10px]' />
            Back
          </Link>
        </div>

        <section className="flex flex-col items-center gap-5">

          <div className="w-full flex flex-col xl:gap-16 gap-5 rounded-lg eventscroll px-[20px] xl:px-[160px]">

            <div className="flex xl:flex-row flex-col items-center justify-start xl:gap-10 gap-10">
              <img src={job.jobCover} alt="" className='w-auto object-fill h-[auto] max-h-[350px] rounded-xl shadow-xl' />

              <div className="flex flex-col">

                <p className="font-pressura text-violet xl:text-[36px] uppercase text-[18px]">{job.jobtitle}</p>

              </div>

            </div>

            <p className="font-agrandir text-black text-[14px] text-justify" dangerouslySetInnerHTML={{ __html: job?.content }} />

          </div>

          <div className="w-full flex justify-center items-center mt-5">
            {job.type === 'Volontariat' ?
              <a href="mailto:pmitogo@pmi-togo.org" className="buttonviolet">Postuler</a>
              :
              <Link to={job.directLink} target="_blank" rel="noopener" className="buttonviolet">Postuler</Link>
            }
          </div>

        </section>

      </div>

    </div>)
}

export default JobDetail