import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Timestamp, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../../../utils/firebase';
import { format, parseISO } from 'date-fns';
import { fr } from "date-fns/locale";
import { IoMdCalendar } from 'react-icons/io';
import { FaLink } from 'react-icons/fa';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdOutlineSmsFailed } from 'react-icons/md';
import Modal from '../../../../components/modal/Modal';
import { FaAnglesLeft } from 'react-icons/fa6';


function EventView() {

    const { id } = useParams();
    const [event, setevent] = useState([]);
    const [isVisible, setIsVisible] = useState([]);
    const [isDone, setIsDone] = useState(false);

    //read this event data
    useEffect(() => {
        const readThiseventData = async () => {
            const docRef = doc(firestoreDatabase, 'events', id);
            const docSnap = await getDoc(docRef);
            setevent(docSnap.data());
            setIsVisible(docSnap.data().isVisible);
        };
        readThiseventData();
    }, [id]);

    const eventCover = { backgroundImage: `url(${event?.coverUrl})` };

    //Archiver un event
    const [isArchiving, setIsArchiving] = useState(false);

    const archivateEvent = async (event) => {
        event.preventDefault();
        setIsArchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'events', id);

        try {

            await updateDoc(articleDocRef, {
                isVisible: false,
                lastModif: Timestamp.now(),
            });

            setIsArchiving(false);
            navigate(-1);
        } catch (error) {
            setIsArchiving(false);
            console.error("Error updating article: ", error);
        }
    };

    //Désarchiver un event
    const [isDisarchiving, setIsDisarchiving] = useState(false);

    const desarchivateEvent = async (event) => {
        event.preventDefault();
        setIsDisarchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'events', id);

        try {

            await updateDoc(articleDocRef, {
                isVisible: true,
                lastModif: Timestamp.now(),
            });

            setIsDisarchiving(false);
            navigate(-1);
        } catch (error) {
            setIsDisarchiving(false);

        }
    };

    const deleteEvent = async () => {
        try {

            // Accédez au document dans Firestore
            const docRef = doc(firestoreDatabase, 'events', id);

            // Supprimez le document
            await deleteDoc(docRef);
            setIsDone(true);
            setOpen(true);

            // Vous pouvez également effectuer d'autres actions après la suppression, comme rediriger l'utilisateur ou afficher un message de confirmation.
        } catch (error) {
            setIsDone(false);
            setOpen(true);
            // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
        }
    };

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    }


    return (
        <div className="px-[60px]">

            <section className="flex pb-[30px]" id='blog'>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <section className="bg-white flex flex-col gap-[30px]">

                <div className="flex flex-col gap-[10px] pb-[150px]">

                    <div className="w-full bg-cover rounded-md xl:h-[400px]" style={eventCover} />

                    <div className="flex flex-col gap-2.5">
                        <p className="font-pressura xl:text-[28px] text-[20px] text-black">{event?.eventName}</p>

                        <div className="flex items-center gap-[5px] font-pressura">
                            <IoMdCalendar />
                            <span className='xl:text-[14px] text-[10px]'>
                                {event?.date && format(parseISO(event.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                            </span>
                        </div>

                        {event.type === 'presential' ?
                            <div className="flex items-center gap-[5px] font-pressura">
                                Lieu:
                                <a href={event.eventMapLink} target="_blank" rel="noopener" className="text-blue-400 w-fit cursor-pointer overflow-hidden text-ellipsis">{event.eventLinkOrPlace}</a>
                            </div>
                            :
                            <div className="flex items-center gap-[5px] xl:text-[16px] text-[14px] font-pressura w-full">
                                Lien de participation:
                                <a href={event.eventLinkOrPlace} target="_blank" rel="noopener" className="flex items-center gap-[5px] text-blue-400 w-fit h-5 cursor-pointer">
                                    Cliquez ici <FaLink className='text-[12px]' />
                                </a>
                            </div>
                        }

                        <p className="font-agrandir xl:text-[16px] text-[16px] text-black text-justify" dangerouslySetInnerHTML={{ __html: event?.content }}></p>

                    </div>

                </div>

                <div className="flex gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/eventapp/edit/" + id} className='buttonblack'>Modifier</Link>

                    {isVisible ? (
                        <button
                            onClick={archivateEvent}
                            className='buttonblack'
                            type='button'
                        >
                            {isArchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Archiver</p>
                            )}
                        </button>

                    ) : (

                        <button
                            onClick={desarchivateEvent}
                            className='buttonblack'
                            type='button'
                        >
                            {isDisarchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Désarchiver</p>
                            )}
                        </button>
                    )}

                    {isVisible === false && (
                        <button
                            className='buttonblack'
                            type='button'
                            onClick={deleteEvent}
                        >
                            Supprimer
                        </button>
                    )}
                </div>

            </section>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            {isDone ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>L'évènement a été supprimé !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}
                            <button
                                className='buttonviolet w-[50px!important]'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }

        </div>
    )
}

export default EventView