import React from 'react'
import { Link } from 'react-router-dom'

const EventArticleCard = ({ id, coverImg, title, date }) => {
  return (
    <div className="bg-lightgrey flex xl:flex-row flex-col items-center gap-5 xl:px-[30px] px-[60px] xl:h-[200px] xl:max-w-[800px] w-full rounded-md shadow-md">

      <div className="xl:w-3/6 w-full h-[150px] bg-cover rounded-md" style={{ backgroundImage: `url(${coverImg})` }} />

      <div className="xl:w-3/6 flex flex-col gap-5 justify-between px-[30px]">

        <div className="flex flex-col">
          <p className="title font-bold font-pressura text-tangerine text-[18px]">{title}</p>

          <p className="font-agrandir text-black text-[12px] mt-[0px]">{date}</p>
        </div>

        <Link to={"/dashboard/eventapp/eventview/" + id} className="font-pressura cursor-pointer flex items-center buttonviolet text-[14px]">Accéder à l'évènement</Link>

      </div>
    </div>
  )
}

export default EventArticleCard