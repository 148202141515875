import React, { useEffect, useState } from "react";
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";
import icon from "../../assets/logo/favicon.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import { IoIosMenu } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";

const Header = () => {
  const location = useLocation();
  const activeSidenavLinkCss = "headerNavLinkActive";
  const sidenavLinkCss = "headerNavLink";

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Faire défiler vers l'élément avec l'ID correspondant au hachage dans l'URL
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const Menu = [
    {
      label: "Accueil",
      link: "/#hero",
    },
    {
      label: "PMI Togo Chapter",
      link: "/we#we",
      links: [
        { label: "Qui Sommes-nous ?", link: "/we#we", out: false },
        {
          label: "Objectifs & Missions",
          link: "/we#objectifs&missions",
          out: false,
        },
        { label: "Membres dirigeants", link: "/we#headmember", out: false },
        { label: "Volontaires", link: "/we#volunteers", out: false },
      ],
    },

    {
      label: "Membres",
      link: "/members#member",
      links: [
        { label: "Avantages", link: "/members#advantages", out: false },
        { label: "Volontariat", link: "/members#volunteering", out: false },
      ],
    },

    {
      label: "Carrières",
      link: "/devpro#prodev",
      links: [
        { label: "Certifications", link: "/devpro#certifications", out: false },
        { label: "Formations", link: "/devpro#formations", out: false },
        { label: "Offres d'emploi", link: "/devpro#jobs", out: false },
      ],
    },

    {
      label: "Évenements",
      link: "/events#evenements",
      links: [
        { label: "Evènements locaux", link: "/events#localevents", out: false },
        { label: "Webinaires", link: "/events#webinaires", out: false },
        {
          label: "PMI Africa Conférence",
          link: "https://pmiafricaconference.com/",
          out: true,
        },
        {
          label: "PMI Global",
          link: "https://www.pmi.org/events/calendar",
          out: true,
        },
      ],
    },

    {
      label: "Publications",
      link: "/blog#blog",
      links: [
        { label: "Blog", link: "/blog#blog", out: false },
        { label: "Documentation", link: "blog/docs#docs", out: false },
      ],
    },

    // {
    //   label: "CONTACT",
    //   link: "/contact#contact",
    // },
  ];

  return (
    <header className="bg-white shadow z-[48] relative">
      {/* Web header */}
      <section
        className="h-[20vh] hidden lg:grid max-h-[140px] bg-white shadow-lg fixed top-0 w-full"
        id="webNav"
      >
        <section className="h-[100px] xl:mb-5 flex flex-wrap items-center justify-between py-[10px] w-full max-w-screen-xl mx-auto p-4">
          <Link to={"/"}>
            <img src={logo} className="xl:h-[100px] lg:h-[70px]" alt="PMI Chapter Togo" />
          </Link>

          <div className="flex items-center justify-center gap-3 font-agrandir">
            <Link to={"/devpro#certifications"} className="headerLinkGray">
              CERTIFICATIONS
            </Link>
            <div className="w-[1px] h-3 bg-gray-400 rounded mb-1"></div>
            <Link to={"/devpro#jobs"} className="headerLinkGray">
              OFFRES <span className="px-0.5"></span> D'EMPLOI
            </Link>
            <div className="w-[1px] h-3 bg-gray-400 rounded mb-1"></div>

            <Link to={"/contact#contact"} className="headerLinkGray">
              NOUS <span className="px-0.5"></span> CONTACTER
            </Link>
            <div className="w-[2px] h-3 bg-gray-400 rounded mb-1"></div>
            <a
              href="https://idp.pmi.org/account/login"
              target="_blank"
              rel="noreferrer"
              className="headerLinkViolet"
            >
              Se <span className="px-0.5"></span> Connecter
            </a>
            <a
              href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter"
              target="_blank"
              rel="noreferrer"
              className="headerBtnViolet ml-5"
            >
              DEVENIR <span className="px-0.5"></span> MEMBRE
            </a>
          </div>
        </section>

        <nav className="navLinkBar border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-end mx-auto p-4">
            <div
              className="hidden w-full md:block md:w-auto"
              id="navbar-solid-bg"
            >
              <ul className="flex gap-[40px] h-full">
                {Menu.map((item, idx) =>
                  item.links ? (
                    <li className="relative group" key={idx}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? activeSidenavLinkCss : sidenavLinkCss
                        }
                        to={item.link}
                      >
                        <div className="cursor-pointer flex gap-[5px] h-full items-center hover:text-violet text-[13.5px]">
                          <p className="">{item.label}</p>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            x="0px"
                            y="0px"
                            width="8px"
                            height="10px"
                            viewBox="0 0 8 4.8"
                          >
                            <path d="M7.8,1.4L4.6,4.6c-0.3,0.3-0.8,0.3-1.1,0L0.2,1.4C-0.3,0.9,0.1,0,0.8,0h6.4C7.9,0,8.3,0.9,7.8,1.4z"></path>
                          </svg>
                        </div>
                      </NavLink>
                      <div className="absolute hidden group-hover:block bg-white min-w-[250px] w-[max-content] shadow-2xl rounded-b-sm text-[13px]">
                        {item.links.map((line, index) =>
                          line.out === true ? (
                            <Link
                              onClick={closeMenu}
                              key={index}
                              to={line.link}
                              target="_blank"
                              rel="noopener"
                              className="block navSubMenu hover:text-white px-[10px] py-[6px] "
                            >
                              {line.label}
                            </Link>
                          ) : (
                            <Link
                              onClick={closeMenu}
                              key={index}
                              to={line.link}
                              className="block navSubMenu hover:text-white px-[10px] py-[6px]"
                            >
                              {line.label}
                            </Link>
                          )
                        )}
                      </div>
                    </li>
                  ) : (
                    <li className="relative group" key={idx}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? activeSidenavLinkCss : sidenavLinkCss
                        }
                        to={item.link}
                      >
                        <div className="cursor-pointer flex gap-[5px] h-full items-center hover:text-violet text-[13.5px]">
                          <p className="">{item.label}</p>
                        </div>
                      </NavLink>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </nav>
      </section>

      {/* Mobile header */}
      <section
        className={`lg:hidden bg-white w-full flex flex-col gap-[15px] ${
          isOpen ? "h-[100dvh] fixed overflow-y-scroll" : "h-[10vh]"
        }`}
      >
        <div
          className={`flex bg-white justify-between items-center p-2.5 h-[10vh] shadow-md w-full ${
            isOpen ? "" : "fixed"
          }`}
          id="mobileNav"
        >
          <Link to={"/"} onClick={closeMenu}>
            <img
              src={isOpen ? icon : logo}
              className="h-[50px] transition-one"
              alt="PMI Chapter Togo"
            />
          </Link>

          <span class="text-violet text-[42px]" onClick={toggleMenu}>
            {isOpen ? <MdOutlineClose /> : <IoIosMenu />}
          </span>
        </div>

        {isOpen && (
          <div className="flex flex-col bg-white justify-between h-full px-5 transition-one">
            <div className="flex flex-col w-full font-pressura">
              {Menu.map((item, idx) =>
                item.links ? (
                  <Dropdown
                    key={idx}
                    title={
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? activeSidenavLinkCss : sidenavLinkCss
                        }
                        to={item.link}
                      >
                        <div className="cursor-pointer flex gap-[5px] items-center">
                          <p className="">{item.label}</p>
                        </div>
                      </NavLink>
                    }
                    content={[
                      <div className="bg-white w-[max-content] text-black">
                        {item.links.map((line, index) =>
                          line.out === true ? (
                            <Link
                              onClick={closeMenu}
                              key={index}
                              to={line.link}
                              target="_blank"
                              rel="noopener"
                              className="block hover:bg-tangerine hover:text-white px-[10px] py-[6px]"
                            >
                              {line.label}
                            </Link>
                          ) : (
                            <Link
                              onClick={closeMenu}
                              key={index}
                              to={line.link}
                              className="block hover:bg-aqua hover:text-white px-[10px] py-[6px]"
                            >
                              {line.label}
                            </Link>
                          )
                        )}
                      </div>,
                    ]}
                  />
                ) : (
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      isActive
                        ? "flex flex-col justify-center border-b-2 py-[15px] px-2.5 text-tangerine"
                        : "flex flex-col justify-center border-b-2 py-[15px] px-2.5"
                    }
                    onClick={closeMenu}
                  >
                    <div className="flex justify-between w-full">
                      <p className="xl:text-[20px] text-[16px]">{item.label}</p>
                    </div>
                  </NavLink>
                )
              )}
            </div>

            <div className="flex flex-col items-center justify-center gap-5 font-agrandir">
              <a
                href="https://idp.pmi.org/account/login"
                target="_blank"
                rel="noopener"
                className="flex items-center justify-center h-fit text-violet"
              >
                Se Connecter
              </a>

              <a
                href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter"
                target="_blank"
                rel="noopener"
                className="flex items-center justify-center h-fit border-2 buttonviolet"
              >
                Nous rejoindre
              </a>
            </div>

            <div className="border-t-2 mx-5">
              <p className="text-grey text-[10px] py-[10px] text-center">
                ©2023 PMI Chapitre Togo . Tous droits réservés.
              </p>
            </div>
          </div>
        )}
      </section>
    </header>
  );
};

export default Header;
