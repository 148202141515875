import React from 'react';
import { FiDownload } from 'react-icons/fi';

const DocLine = ({ title, doc }) => {

    return (
        <div className="flex flex-col justify-center items-center xl:px-[30px] xl:py-5 py-2.5">

            <div className="flex justify-between w-full">
                <p className='xl:text-[20px] text-[15px] text-black'>{title}</p>
                <a href={doc} target="_blank" rel="noopener">
                    <FiDownload className=' text-[24px] cursor-pointer text-tangerine' />
                </a>
            </div>

        </div>
    )
}

export default DocLine