import React, { useState } from 'react';
import { FiDownload } from "react-icons/fi";
import { MdDeleteOutline } from 'react-icons/md';
import Modal from '../../../../components/modal/Modal';
import { firestoreDatabase, storage } from '../../../../utils/firebase';
import { deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';

const UDocline = ({ id, title, docUrl }) => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setShowSuccess(false);
    }

    const deletedoc = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true); // Afficher le loader

            // Accédez au document dans Firestore
            const docRef = doc(firestoreDatabase, 'docs', id);
            const fileRef = ref(storage, `uploads/${title}`);

            // Supprimer le fichier du Firebase Storage
            await deleteObject(fileRef);
            // Supprimez le document
            await deleteDoc(docRef);

            setShowSuccess(true);
            setIsLoading(false);

            // Vous pouvez également effectuer d'autres actions après la suppression, comme rediriger l'utilisateur ou afficher un message de confirmation.
        } catch (error) {
            setIsLoading(false);
            setShowSuccess(false);
            console.log(error)
            // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
        }
    };

    return (
        <div className="flex flex-col justify-center border-b-2 xl:px-[30px] xl:py-5 py-2.5">

            <div className="flex justify-between items-center w-full">
                <p className='text-[20px] text-black'>{title}</p>
                <div className="flex items-center justify-center gap-5">
                    <a href={docUrl} className='' target="_blank" rel="noopener">
                        <FiDownload className='text-[24px] cursor-pointer text-tangerine' />
                    </a>

                    <div className="cursor-pointer bg-tangerine text-white rounded-full p-1">
                        <MdDeleteOutline onClick={handleOpen} className='text-[24px]' />
                    </div>
                </div>

            </div>

            <Modal isOpen={open} onClose={handleClose}>

                {showSuccess ? (
                    <center className='font-agrandir flex flex-col items-center gap-5'>
                        <p>Le document a été supprimé !</p>
                        <button type='button' className="buttonviolet" onClick={handleClose}>OK</button>
                    </center>
                ) : (
                    <div className="flex flex-col gap-5 justify-center items-center">
                        <p className="">
                            Confirmez-vous la suppression de ce document ?
                            {title}
                        </p>

                        <div className=" flex gap-10 items-center">
                            <button onClick={handleClose} className='buttonblack'>Annuler</button>

                            <button onClick={deletedoc} className='buttonviolet'>
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : (
                                    <p>Supprimer</p>
                                )}
                            </button>
                        </div>
                    </div>

                )}
            </Modal>

        </div>
    )
}

export default UDocline