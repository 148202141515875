import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { firestoreDatabase } from '../../../../utils/firebase'
import { Timestamp, doc, updateDoc } from 'firebase/firestore'

const InboxLine = ({ id, name, sentAt, read }) => {

    const navigate = useNavigate();

    const MarkAsRead = async (e) => {
        e.preventDefault()
        const docRef = doc(firestoreDatabase, 'inbox', id)
        try {
            await updateDoc(docRef, {
                read: true,
                readAt: Timestamp.now()
            })
            navigate('/dashboard/inbox/message/' + id + '#message');
        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            {read === true ?
                <Link to={'/dashboard/inbox/message/' + id + '#message'}
                    className="flex justify-between bg-lightgrey border-b-2 p-4 hover:bg-lightgrey cursor-pointer">
                    <p>{name}</p>
                    <p className="">{sentAt}</p>
                </Link>
                :
                <div className="flex justify-between bg-white border-l-2 border-r-2 border-b-2 p-4 hover:bg-lightgrey cursor-pointer"
                onClick={MarkAsRead}>
                    <p>{name}</p>
                    <p className="">{sentAt}</p>
                </div>
            }
        </>
    )
}

export default InboxLine